@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Cardo:regular,italic,700&display=swap");
:root {
  --animation-speed: 0.5s;
  --main-color: #27a776;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cardo", "Roboto", "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", "Geneva", "Verdana", "sans-serif";
  color: #f6f6f6;
  scrollbar-width: none;
}
*::-moz-selection {
  background-color: #27272D;
}
*::selection {
  background-color: #27272D;
}
*::-webkit-scrollbar {
  width: 0px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

h1, h2, h3, h4, h5, p {
  cursor: default;
}

body {
  background-color: #000000;
}