:root {
  --animation-speed: 0.5s;
  --main-color: #27a776;
}

html {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0px;
}

.titles {
  margin-top: 94px;
}
.titles__block {
  position: relative;
  padding: 50px;
}
.titles__block::before {
  content: "";
  position: absolute;
  margin: auto;
  margin-top: 25px;
  width: 50%;
  height: 100%;
  background-color: #000000;
  z-index: -10;
  opacity: 0.6;
  border-radius: 20px;
}

.block-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.block-title__title {
  font-family: "roboto";
  font-size: clamp(2rem, 1.717rem + 1.42vw, 3rem);
  color: var(--main-color);
  font-weight: 900;
}
.block-title__line {
  width: 25%;
  height: 5px;
  border-radius: 2px;
  background-color: var(--main-color);
  opacity: 0.8;
  margin: 15px 0px;
}
.block-title__body {
  margin-top: 25px;
  height: 400px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}
.block-title__item {
  position: relative;
  padding: 10px 5px;
}
.block-title__item::after {
  content: "";
  display: block;
  margin: auto;
  background-color: var(--main-color);
  width: 0%;
  height: 3px;
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transition: all var(--animation-speed) ease;
}
.block-title__item:hover::after {
  opacity: 1;
  visibility: visible;
  width: 100%;
}
.block-title__link {
  font-family: "roboto";
  font-size: clamp(1.5rem, 1.358rem + 0.71vw, 2rem);
}