:root {
  --main-color: #27a776;
}

.swiper-pagination-bullets span {
  background-color: var(--main-color);
}

.not-found {
  margin-top: 94px;
  background-color: #000000;
}
.not-found__hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 0;
}
.not-found__hero h1 {
  font-size: clamp(4rem, 2.867rem + 5.66vw, 8rem);
}
.not-found__hero p {
  font-size: 18px;
}
.not-found__information {
  padding-bottom: 25px;
}

.swiper {
  position: relative;
}
.swiper-slide {
  display: flex;
  justify-content: center;
}
.swiper-slide-pic {
  width: clamp(20rem, 4.425rem + 77.88vw, 75rem);
}
.block-404 {
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
}
@media (max-width: 1370px) {
  .block-404 {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
}
.info__title {
  font-size: 26px;
  margin-bottom: 10px;
}
.info__text {
  font-size: 16px;
}
.info__rectangle {
  margin-top: 25px;
  background-color: #161718;
  padding: 15px;
  border-radius: 10px;
  width: clamp(15.625rem, 7.66rem + 39.82vw, 43.75rem);
}

.rect__quotation {
  font-size: 18px;
}
.rect__photo {
  width: 90px;
  border-radius: 20px;
}
.detail-info {
  position: relative;
  display: flex;
  flex-direction: column;
}
.detail-info__title {
  font-size: 24px;
}
.detail-info__line {
  display: block;
  width: 30%;
  height: 3px;
  border-radius: 5px;
  background-color: var(--main-color);
}
.detail-info__item {
  margin: 10px 0;
}
.detail-info__item p:first-child {
  color: #6b7075;
}
.detail-info__item a {
  color: var(--main-color);
}
.detail-info__button {
  background-color: var(--main-color);
  margin-top: 15px;
  padding: 15px;
  border-radius: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}