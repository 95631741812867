:root {
  --main-color: #27a776;
}

.about {
  margin-top: 94px;
}
.about__info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
@media (max-width: 820px) {
  .about__info {
    flex-direction: column;
  }
}
.about__reviews {
  margin: 40px 30px;
}

.block__title {
  font-size: 2rem;
}
.block__body {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
@media (max-width: 820px) {
  .block__body {
    flex-direction: column;
  }
}
.block__item {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 15px;
}
.block__item svg {
  position: absolute;
  top: -2px;
  left: -20px;
}
.block__item a {
  color: var(--main-color);
}
.block__item b {
  cursor: default;
}

.reviews__text {
  position: relative;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 18px;
}
.reviews__text span {
  display: block;
  background-color: var(--main-color);
  width: 200px;
  height: 4px;
  opacity: 0.7;
  border-radius: 2px;
}
.reviews__title {
  font-size: clamp(2rem, 1.717rem + 1.42vw, 3rem);
  margin-bottom: 20px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-slide-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #161718;
  width: 300px;
  height: 400px;
  gap: 10px;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
}
.swiper-slide-item h1 {
  margin-top: auto;
}
.swiper-slide-item p {
  margin-bottom: 10px;
}
.swiper-pagination-bullets span {
  background-color: var(--main-color);
}