:root {
  --animation-spead: 0.5s;
  --main-color: #27a776 ;
}

body {
  background-color: #000000;
}

.hero-chapters {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 92px 25px 30px 25px;
}
.hero-chapters__title {
  font-family: "Roboto";
  font-size: clamp(2rem, 1.292rem + 3.54vw, 4.5rem);
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}
.hero-chapters__text {
  font-size: clamp(1.125rem, 0.877rem + 1.24vw, 2rem);
  text-align: center;
}
.hero-chapters__text a {
  color: var(--main-color);
}

.gallery {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.gallery__image {
  display: block;
  margin: auto;
  width: clamp(18.75rem, 8.131rem + 53.1vw, 56.25rem);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 25px;
}
.buttons__button {
  width: 200px;
  padding: 10px;
  background-color: var(--main-color);
  border-radius: 20px;
  text-align: center;
}