.header {
  position: fixed;
  top: 0%;
  width: 100vw;
  background-color: #000000;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  padding: 15px 15px;
}
.header__left-side {
  display: flex;
  align-items: center;
  gap: 15px;
}
.header__middle-side {
  display: flex;
  align-items: center;
}
.header__right-side {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left__logo img {
  width: 100%;
  height: 100%;
}
.left__logo-text {
  font-size: clamp(1.5rem, 1.358rem + 0.71vw, 2rem);
}
@media (max-width: 425px) {
  .left__logo-text {
    display: none;
  }
}

.middle__navigation {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .navbar {
    position: absolute;
    display: flex;
    left: -100%;
    top: 0px;
    width: 80vw;
    height: 100vh;
    visibility: hidden;
    background-color: var(--main-color);
    transition: all var(--animation-speed) ease;
    z-index: 1000;
  }
  .navbar.active-burger-menu {
    left: 0px;
    visibility: visible;
  }
}
.navbar__body {
  display: flex;
  align-items: center;
  gap: clamp(0.938rem, 0.584rem + 1.77vw, 2.188rem);
}
@media (max-width: 768px) {
  .navbar__body {
    margin: auto;
    flex-direction: column;
  }
}
.navbar__link {
  position: relative;
  font-size: clamp(0.875rem, 0.84rem + 0.18vw, 1rem);
  justify-content: center;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .navbar__link {
    font-size: 20px;
  }
}
.navbar__link::after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  border-radius: 3px;
  background-color: var(--main-color);
  transition: all var(--animation-speed) ease;
}
@media (max-width: 768px) {
  .navbar__link::after {
    background-color: #000000;
  }
}
.navbar__link:hover::after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.submenu {
  position: relative;
}
.submenu__body {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  transform: translateY(50%);
  transition: all var(--animation-speed) ease;
}
.submenu__link {
  position: relative;
  font-size: clamp(0.875rem, 0.84rem + 0.18vw, 1rem);
  bottom: -5px;
}
@media (max-width: 768px) {
  .submenu__link {
    font-size: 20px;
  }
}

.right__social {
  display: flex;
  align-items: center;
  gap: 5px;
}
.right__link {
  margin-left: 15px;
}
.right__burger {
  position: relative;
  display: block;
  width: 30px;
  height: 18px;
  cursor: pointer;
  z-index: 1000;
  transition: all var(--animation-speed) ease;
}
@media (min-width: 768px) {
  .right__burger {
    display: none;
  }
}
.right__burger span {
  top: calc(50% - 1px);
  left: 0;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transition: all var(--animation-speed) ease;
}
.right__burger span:first-child {
  top: 0px;
}
.right__burger span:last-child {
  top: auto;
  bottom: 0px;
}
.right__burger.burger-menu-active {
  transform: translateX(220%);
}
.right__burger.burger-menu-active span {
  transform: scale(0);
}
.right__burger.burger-menu-active span:first-child {
  transform: rotate(135deg);
  top: calc(50% - 1px);
}
.right__burger.burger-menu-active span:last-child {
  transform: rotate(45deg);
  bottom: calc(50% - 1px);
}

.icon {
  fill: #f6f6f6 !important;
  transition: all var(--animation-speed) ease;
}
.icon:hover {
  fill: var(--main-color) !important;
}

.active-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateY(20%);
  transition: all var(--animation-speed) ease;
}