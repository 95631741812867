:root {
  --animation-speed: 0.5s;
  --main-color: #27a776;
}

.auth {
  margin-top: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.auth__container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 95%;
  padding: 20px;
}
.auth__container::before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 110%;
  height: 100%;
  background-color: #0C593B;
  border-radius: 20px;
}
.auth__general {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}
.auth__inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 5px;
}
.auth__additional-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth__additional-buttons .additional-buttons__submitbtn {
  cursor: pointer;
  padding: 15px 60px;
  background: var(--main-color);
  border: none;
  border-radius: 15px;
  margin: 10px 0;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 35px;
}
.auth__additional-buttons .additional-buttons__forgot_text {
  margin-left: auto;
  cursor: pointer;
}

.inputs {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 15px;
}
.inputs:nth-child(2), .inputs:nth-child(3) {
  margin-top: 25px;
}
.inputs__input {
  position: relative;
  width: 100%;
  height: 40px;
  outline: none;
  padding: 5px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 2px solid var(--main-color);
}
.inputs__input:focus ~ span, .inputs__input:valid ~ span, .inputs__input:active ~ span {
  color: #ffffff;
  transform: translate(20%, -105%);
}
.inputs__text {
  position: absolute;
  color: #000000;
  margin-left: 8px;
  font-size: 20px;
  font-weight: 700;
  transition: all var(--animation-speed) ease;
  pointer-events: none;
}