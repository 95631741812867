.alertBox {
  position: absolute;
  left: 5%;
  bottom: 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  gap: 15px;
  background-color: #ffa500;
  padding: 15px;
  border-radius: 20px;
  z-index: 10;
}
.alertBox__title {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}
.alertBox__line {
  width: 100%;
  height: 3px;
  background-color: #FF5F15;
  border-radius: 4px;
}
.alertBox.showAlert {
  visibility: visible;
  opacity: 1;
}