.hero {
  position: relative;
  margin-top: 94px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 25px;
}
.hero__title {
  position: relative;
  margin: 0 25px;
  font-size: clamp(2rem, 1.717rem + 1.42vw, 2.7rem);
  text-align: center;
  z-index: 5;
  line-height: 1;
}
.hero__text {
  font-size: clamp(0.875rem, 0.769rem + 0.53vw, 1.25rem);
}
.hero__text-link {
  color: var(--main-color);
}
.hero__button {
  background-color: var(--main-color);
  border-radius: 10px;
  padding: 15px 25px;
  margin-top: 20px;
  cursor: pointer;
  transition: all var(--animation-speed) ease;
}
.hero__button:hover {
  opacity: 0.7;
}