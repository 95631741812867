:root {
  --main-color: #27a776;
  --second-color: #0C593B;
  --animation-speed: 0.5s;
}

.profile {
  position: fixed;
  margin-top: 100px;
  width: 100vw;
  height: 80vh;
  border-top: 3px solid var(--second-color);
}
.profile__container {
  display: flex;
  width: 80%;
  height: 100%;
  margin: auto;
  flex-direction: column;
  align-items: flex-end;
  border: 2.5px solid var(--second-color);
  border-top: none;
  border-radius: 20px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.profile-sections__top {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  padding: 15px;
  border-bottom: 2.5px solid var(--second-color);
}
.profile-sections__bottom {
  display: flex;
  width: 100%;
}

.section-top__log-out-button {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
}
.section-top__text {
  font-size: 1.4rem;
}
.section-top__profile-img {
  position: relative;
  width: 48px;
}
.section-top__edit-button {
  cursor: pointer;
}

.section-bottom {
  height: 100%;
}
.section-bottom__main-content {
  width: 70%;
  margin: 15px;
}
.section-bottom__actions {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.actions-type__item {
  cursor: pointer;
  font-size: clamp(1rem, 0.773rem + 0.97vw, 1.5rem);
  width: 110%;
  height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.3s ease;
}
@media (min-width: 768px) {
  .actions-type__item {
    width: 60%;
  }
}
.actions-type__item p {
  margin-right: 15px;
  cursor: pointer;
}
.actions-type__item:last-child {
  margin-top: auto;
  font-size: 1.2rem;
}
.actions-type__item:last-child:hover, .actions-type__item:last-child.active-section {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 16px;
}
.actions-type__item:first-child:hover, .actions-type__item:first-child.active-section {
  border-top-left-radius: 0px;
}
.actions-type__item:hover, .actions-type__item.active-section {
  background-color: var(--second-color);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}